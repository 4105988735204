// Generated by Framer (68f6254)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jAq1bEjzP", "H79U6ydPJ"];

const serializationHash = "framer-TubTS"

const variantClassNames = {H79U6ydPJ: "framer-v-2bl20o", jAq1bEjzP: "framer-v-1g0khrl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "jAq1bEjzP", Mobile: "H79U6ydPJ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "jAq1bEjzP"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "jAq1bEjzP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.footer {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1g0khrl", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"jAq1bEjzP"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({H79U6ydPJ: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><motion.div className={"framer-tkmxow"} layoutDependency={layoutDependency} layoutId={"SiLvFlMC7"}/></motion.footer></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TubTS.framer-1ie7147, .framer-TubTS .framer-1ie7147 { display: block; }", ".framer-TubTS.framer-1g0khrl { display: grid; gap: 0px; grid-auto-rows: min-content; grid-template-columns: repeat(4, minmax(1px, 1fr)); grid-template-rows: repeat(1, min-content); height: min-content; justify-content: start; overflow: hidden; padding: 20px 0px 20px 0px; position: relative; width: 1200px; }", ".framer-TubTS .framer-tkmxow { align-content: center; align-items: center; align-self: start; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; justify-self: start; min-height: 26px; min-width: 120px; overflow: hidden; padding: 0px 0px 0px 20px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-TubTS .framer-tkmxow { gap: 0px; } .framer-TubTS .framer-tkmxow > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-TubTS .framer-tkmxow > :first-child { margin-left: 0px; } .framer-TubTS .framer-tkmxow > :last-child { margin-right: 0px; } }", ".framer-TubTS.framer-v-2bl20o.framer-1g0khrl { grid-template-columns: repeat(2, minmax(1px, 1fr)); padding: 20px 0px 0px 0px; }", ".framer-TubTS.framer-v-2bl20o .framer-tkmxow { min-height: 46px; padding: 0px 0px 20px 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 66
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"H79U6ydPJ":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkhBVVSLgX: React.ComponentType<Props> = withCSS(Component, css, "framer-TubTS") as typeof Component;
export default FramerkhBVVSLgX;

FramerkhBVVSLgX.displayName = "Footer";

FramerkhBVVSLgX.defaultProps = {height: 66, width: 1200};

addPropertyControls(FramerkhBVVSLgX, {variant: {options: ["jAq1bEjzP", "H79U6ydPJ"], optionTitles: ["Default", "Mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerkhBVVSLgX, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})